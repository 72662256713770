import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../layout"
import SEO from "../components/SEO"
import Textblock from "../components/Textblock"
import { DangerousHTML } from "../components/contentful/HTML"

interface DataType {
  page: Contentful.BasicPage
}

const BasicPageTemplate = ({ data }: PageProps<DataType>) => {
  const { page } = data

  return (
    <Layout narrow>
      <SEO title={page.metadata.title} description={page.metadata.description} keywords={page.metadata.keywords} />
      <Textblock>
        <h1>{page.title}</h1>
        <DangerousHTML>{page.body}</DangerousHTML>
      </Textblock>
    </Layout>
  )
}

export default BasicPageTemplate

export const pageQuery = graphql`
  query ($slug: String!, $locale: String!) {
    page: contentfulBasicPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        title
        description
        keywords
      }
    }
  }
`
